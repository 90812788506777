import useAuth from 'hooks/useAuth';
import { useConfirmationModalContext } from 'hooks/useConfirmationModal';
import { useEffect, useCallback } from 'react';
import { useSelector, dispatch } from 'store';
import { hide } from 'store/slices/modal';

function GeneralAlert() {
    const { logout } = useAuth();
    const modalSelector = useSelector((state) => state.modal);
    const serviceTokenExpired = window.localStorage.getItem('serviceTokenExpired');

    const modal = useConfirmationModalContext();

    const handleOnAction = useCallback(async () => {
        try {
            logout();
        } catch (err) {
            console.error(err);
        }
    }, [logout]);

    useEffect(() => {
        (async () => {
            if (modalSelector.show) {
                dispatch(hide({}));
                await modal.showConfirmation({
                    title: 'Session Expired',
                    content: `Your session has expired. Please log in again to continue.`,
                    loadingText: 'Updating',
                    forwardButtonText: 'Ok',
                    actionButtonText: '',
                    onForward: null,
                    onAction: null,
                    showCheckbox: false,
                    actionType: '',
                    sectionTitle: 'session',
                    recordType: '',
                    showActionButton: false,
                    hideCloseBtn: true
                });
            }
        })();
    }, [modalSelector.show, modal, handleOnAction]);

    useEffect(() => {
        (async () => {
            if (serviceTokenExpired === 'expired') {
                window.localStorage.removeItem('serviceTokenExpired');
                await modal.showConfirmation({
                    title: 'Session Expired',
                    content: `Your session has expired. Please log in again to continue.`,
                    loadingText: 'Updating',
                    forwardButtonText: 'Ok',
                    actionButtonText: '',
                    onForward: handleOnAction,
                    onAction: null,
                    showCheckbox: false,
                    actionType: '',
                    sectionTitle: 'session',
                    recordType: '',
                    showActionButton: false,
                    hideCloseBtn: true
                });
            }
        })();
    }, [modal, handleOnAction, serviceTokenExpired]);

    return <div />;
}

export default GeneralAlert;
