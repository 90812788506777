// types
import { createSlice } from '@reduxjs/toolkit';

// initial state
const initialState: { show: boolean } = {
    show: false
};

// ==============================|| SLICE - MODAL ||============================== //

const modal = createSlice({
    name: 'modal',
    initialState,
    reducers: {
        show(state, action) {
            state.show = true;
        },

        hide(state, action) {
            state.show = false;
        }
    }
});

export default modal.reducer;

export const { show, hide } = modal.actions;
