// routing
import Routes from 'routes';

// project imports
import Locales from 'ui-component/Locales';
import NavigationScroll from 'layout/NavigationScroll';
import RTLLayout from 'ui-component/RTLLayout';
import Snackbar from 'ui-component/extended/Snackbar';
import ThemeCustomization from 'themes';
import { SocketContext, socket } from 'contexts/socket';
// auth provider
import { ConfirmationModalContextProvider } from 'components/providers/ConfirmationModalProvider';
// import { FirebaseProvider as AuthProvider } from 'contexts/FirebaseContext';
import { AWSCognitoProvider as AuthProvider } from 'contexts/AWSCognitoContext';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import GeneralAlert from 'ui-component/extended/GeneralAlert';
// import { JWTProvider as AuthProvider } from 'contexts/JWTContext';
// import { Auth0Provider as AuthProvider } from 'contexts/Auth0Context';

// ==============================|| APP ||============================== //

const App = () => {
    const location = useLocation();

    useEffect(() => {
        if (location.pathname === '/login') {
            const script = document.createElement('script');
            script.src = 'https://www.creativeperceptive.com/js/799833.js';
            script.async = true;
            document.body.appendChild(script);

            const noscript = document.createElement('noscript');
            const img = document.createElement('img');
            img.src = 'https://www.creativeperceptive.com/799833.png';
            img.style.display = 'none';
            noscript.appendChild(img);
            document.body.appendChild(noscript);

            return () => {
                document.body.removeChild(script);
                document.body.removeChild(noscript);
            };
        }
        return () => {};
    }, [location.pathname]);

    return (
        <ThemeCustomization>
            {/* RTL layout */}
            <RTLLayout>
                <Locales>
                    <NavigationScroll>
                        <AuthProvider>
                            <ConfirmationModalContextProvider>
                                <SocketContext.Provider value={socket}>
                                    <>
                                        <Routes />
                                        <Snackbar />
                                        <GeneralAlert />
                                    </>
                                </SocketContext.Provider>
                            </ConfirmationModalContextProvider>
                        </AuthProvider>
                    </NavigationScroll>
                </Locales>
            </RTLLayout>
        </ThemeCustomization>
    );
};

export default App;
